import memoize from 'lodash/memoize'
import { Token } from '@pancakeswap/sdk'
import { ChainId } from '@pancakeswap/chains'
import { safeGetAddress } from 'utils'
import { isAddress } from 'viem'

const mapping = {
  [ChainId.BSC]: 'smartchain',
  [ChainId.ETHEREUM]: 'ethereum',
  [ChainId.POLYGON_ZKEVM]: 'polygonzkevm',
  [ChainId.ZKSYNC]: 'zksync',
  [ChainId.ARBITRUM_ONE]: 'arbitrum',
  [ChainId.LINEA]: 'linea',
  [ChainId.ZEUSCHAIN]: 'zeuschain',
  [ChainId.PULSECHAIN]: 'token-logo',
  [ChainId.UNICHAIN_SEPOLIA]: 'unichain-sepolia',
  [ChainId.WORLDCHAIN]: 'worldchain',
  [ChainId.APECHAIN]: 'apechain',
  [ChainId.MORPHCHAIN]: 'morphchain',
  [ChainId.INKCHAIN]: 'inkchain',
  [ChainId.BASECHAIN]: 'base-logos',
  [ChainId.SONIC]: 'sonic-logos',
}

const getTokenLogoURL = memoize(
  (token?: Token) => {


    if (token && mapping[token.chainId] && isAddress(token.address)) {
      return `https://raw.githubusercontent.com/9mm-exchange/app-tokens/refs/heads/main/${mapping[token.chainId]}/${safeGetAddress(
        token.address,
      )}.png`;
    }

    return null;
  },
  (t) => `${t?.chainId}#${t?.address}`,
);


export const getTokenLogoURLByAddress = memoize(
  (address?: string, chainId?: number) => {
    if (address && chainId && mapping[chainId] && isAddress(address)) {
      return `https://raw.githubusercontent.com/9mm-exchange/app-tokens/refs/heads/main/${mapping[chainId]}/assets/${safeGetAddress(
        address,
      )}/logo.png`
    }
    return null
  },
  (address, chainId) => `${chainId}#${address}`,
)

export default getTokenLogoURL
